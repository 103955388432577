<template>
    <div>
        <v-card flat class="mb-12">
            <h3 v-if="isLigue" class="mb-8">
                Votre comité départemental vous offre votre cotisation, il sera averti de votre adhésion.
            </h3>
            <h3 v-else-if="isPatientsreseau">
                Patient en réseau vous offre votre cotisation et sera averti de votre adhésion.
            </h3>
            <h3 v-else-if="isRetreat && currentPartner.id" class="mb-8">
                Adhésion offerte par {{ partnerName }}
            </h3>
            <v-form ref="adhCodeForm" v-on:submit.prevent="checkAdhSecretCode">
                <v-autocomplete v-if="isLigue" :rules="[rules.required]" v-model="currentPartner.id"
                                outlined :items="partners.ligue" :item-text="formatZipCode" item-value="id"
                                label="Merci de sélectionner le département de votre comité"></v-autocomplete>

                <v-text-field v-if="isLigue || (isRetreat && currentPartner.id) || isPatientsreseau"
                              v-model="currentPartner.adh_secret_code" outlined :rules="[rules.required]"
                              :label="secretCodeLabel"></v-text-field>

                <p class="mb-8" v-if="isLigue || isPatientsreseau">
                    Le code secret n'est pas conforme ou ne m'a pas été communiqué,
                    <a v-if="isLigue" target="_blank"
                       href="https://www.ligue-cancer.net/la-ligue-pres-de-chez-vous-comite">
                        je contacte mon comité départemental
                    </a>
                    <a v-else-if="isPatientsreseau" target="_blank" href="https://www.patientsenreseau.fr/contact/">
                        je contacte patient en réseau
                    </a>
                </p>
            </v-form>
        </v-card>

        <v-btn @click="checkAdhSecretCode">
            Valider
        </v-btn>
    </div>
</template>

<script>

export default {
    props: {
        orientation: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            currentPartner: { ...this.orientation.partner },
            partners: {
                ligue: [],
                retreat: []
            },
            rules: {
                required: value => !!value || 'Champ requis'
            }
        }
    },
    async mounted () {
        await this.fetchPartners()
    },
    computed: {
        isLigue () {
            return this.orientation.reason === 'ligue'
        },
        isRetreat () {
            return this.orientation.reason === 'retreat'
        },
        isPatientsreseau () {
            return this.orientation.reason === 'patientsreseau'
        },
        partnerName () {
            const partner = this.partners[this.orientation.reason].find(p => p.id === this.orientation.partner.id)
            return partner ? partner.name : ''
        },
        secretCodeLabel () {
            if (this.isLigue) {
                return 'Merci de saisir le code fourni par votre comité'
            } else if (this.isRetreat) {
                return `Merci de saisir le code fourni par ${this.partnerName}`
            } else {
                return `Merci de saisir le code fourni par Patients en réseau`
            }
        }
    },
    methods: {
        async fetchPartners () {
            try {
                const [ligueResponse, retreatResponse] = await Promise.all([
                    this.$store.dispatch('partnersRequest', { search: JSON.stringify({ support_type: 'ligue' }) }),
                    this.$store.dispatch('partnersRequest', { search: JSON.stringify({ support_type: 'retreat' }) })
                ])

                this.partners.ligue = ligueResponse.data
                this.partners.retreat = retreatResponse.data
            } catch (error) {
                console.error('Failed to fetch partners:', error)
            }
        },
        formatZipCode (item) {
            return `${item?.zip?.slice(0, (item.zip.substring(0, 2) === '97' || item.zip.substring(0, 2) === '98') ? 3 : 2) + ' - ' ?? ''}${item.name}`
        },
        async checkAdhSecretCode () {
            if (this.$refs.adhCodeForm.validate()) {
                try {
                    await this.$store.dispatch('partnerCheckAdhSecretCode', this.currentPartner)
                    this.$emit('submitAdhCodeForm', { ...this.orientation, partner: this.currentPartner })
                } catch (err) {
                    const partnerName = this.retreatPartners.find(p => p.id === this.orientation.partner.id)?.name || ''
                    const errorMsg = `${err.response.data.error}, renseignez-vous auprès de ${this.isPatientsreseau ? 'Patients en réseau' : partnerName}`
                    await this.$store.dispatch('setErrors', errorMsg)
                }
            }
        }
    }
}
</script>
